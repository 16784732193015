import React from 'react';
import { useFormatter, useNow, useTranslations } from 'next-intl';

import { Badge } from '@zealy/design-system';
import { dayjs } from '@zealy/utils';

import type { QuestData } from '../QuestCard.types';

export const VoteBadge = ({ quest }: { quest: QuestData }) => {
  const t = useTranslations('quest.vote-badge');
  const { relativeTime } = useFormatter();
  const endDate = quest.conditions?.find(
    condition => condition.type === 'date' && condition.operator === '<',
  )?.value;
  const voteReward = quest.rewards.find(
    reward => reward.method?.type === 'vote' && reward.status === 'in-progress',
  );

  const now = useNow({
    updateInterval: endDate && dayjs(endDate).isAfter(dayjs()) ? 1000 : 0,
  });

  const endingIn = endDate ? relativeTime(new Date(endDate), now) : '';

  if (!endingIn || !voteReward) {
    return null;
  }

  return (
    <Badge variant="brand">
      {t('ending', {
        relativeTime: endingIn,
      })}
    </Badge>
  );
};
