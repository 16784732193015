import React from 'react';

import { Skeleton } from '@zealy/design-system';

import { cn } from '#utils/utils';

import { styles } from './ModuleCard.styles';

export const ModuleCardSkeleton = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('', 'w-full rounded-module-card-module-card', className)} {...rest}>
    <div className={styles.header({})}>
      <div className="flex flex-col gap-300">
        <Skeleton className="w-full h-300" />
        <div className="flex flex-col gap-100">
          <Skeleton className="w-[50px] h-100" />
          <div className="flex flex-row gap-100">
            <Skeleton className="w-full h-100" />
            <Skeleton className="w-[20px] h-100" />
          </div>
        </div>
      </div>
    </div>
    <div
      className={styles.content({
        className: 'box-decoration-clone overflow-y-hidden border-module-card',
      })}
    >
      <Skeleton className="w-full min-h-[100px] rounded-xl" />
      <Skeleton className="w-full min-h-[100px] rounded-xl" />
      <Skeleton className="w-full min-h-[100px] rounded-xl" />
    </div>
  </div>
);
