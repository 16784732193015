function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function hexToRgbString(hex: string) {
  if (!hex.startsWith('#')) return undefined;
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the hex color string
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

export const calculateBrightness = (hex: string) => {
  const rgb = hexToRgb(hex);

  if (!rgb) return 'dark';

  const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
  return brightness > 125 ? 'light' : 'dark';
};
