import { useMemo } from 'react';

import { useQuestBoard } from '@zealy/queries';
import { GetQuestboardOutput } from '@zealy/utils';

type QuestAsObject = Record<
  GetQuestboardOutput[number]['quests'][number]['id'],
  GetQuestboardOutput[number]['quests'][number]
>;

/**
 * Current we don't have a way to get the quest info from the reward conditional,
 * which make us have to get the quest from the questboard and try to match the questId. In a future implementation,
 * to avoid performance issues on webapp we should get the quest info from the reward conditional (backend implementation).
 *
 * Current solution: We take advantage of queries cache and try to find inside the data of questboard the quest with the questId.
 * If we don't find it, we probably will re-trigger the fetch and make response available right way.
 *
 * TODO: Reward conditionals should contain all the information needed to display how to unlock the quest in questboard.
 *
 * @param questIds quests to be found on questboard data.
 */
export const useQuestFromQuestboard = (questIds: string[]) => {
  const { data: questboard } = useQuestBoard(undefined, {
    enabled: questIds.length > 0,
  });

  return useMemo(() => {
    const questsInMatch =
      questboard?.flatMap(questModule => {
        return questModule?.quests?.filter(q => questIds.find(x => x === q.id));
      }) ?? [];

    const questsAsObject = questsInMatch.reduce((acc, quest) => {
      acc[quest.id] = quest;
      return acc;
    }, {} as QuestAsObject);

    return questsAsObject;
  }, [questboard, questIds]);
};
