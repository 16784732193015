import React from 'react';

import { Skeleton } from '@zealy/design-system';

import { cn } from '#utils/utils';

import * as styles from './QuestCard.styles';

export const QuestCardSkeleton = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      styles.container,
      'border pointer-events-none items-center gap-200 p-200',
      className,
    )}
    {...rest}
  >
    <div className="flex flex-1 flex-col gap-100">
      <Skeleton className="w-full h-200" />
      <div className="flex flex-row relative">
        <Skeleton className="w-[72px] h-[24px]" />
      </div>
    </div>
    <Skeleton className="h-reward-card-img-sm w-reward-card-img-sm rounded-xl" />
  </div>
);
