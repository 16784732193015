import { cva } from 'class-variance-authority';

const header = cva(
  [
    'flex flex-col',
    'py-module-card-xl-y rounded-t-module-card-module-card px-module-card-md-x gap-module-card-category-description-CTA',
    'bg-no-repeat bg-top bg-cover bg-[var(--cover)]',
    'justify-end',
    'border-module-card',
  ],
  {
    variants: {
      status: {
        claimable: '-mb-400 pb-800',
        completed: 'rounded-b-md',
        open: '-mb-400 pb-800',
        locked: '-mb-400 pb-800',
        frozen: 'rounded-b-md bg-tertiary',
      },
    },
    defaultVariants: {
      status: 'open',
    },
  },
);

const content = cva(
  [
    'flex flex-col',
    'py-module-card-xl-y px-module-card-md-x gap-module-card-quest-container',
    'rounded-module-card-module-card bg-secondary',
  ],
  {
    variants: {
      variant: {
        frozen: 'h-700',
        locked: 'h-[160px]',
        open: 'h-[160px] max-h-fit',
      },
    },
    defaultVariants: {
      variant: 'open',
    },
  },
);

export const styles = {
  header,
  content,
};
