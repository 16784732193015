import cover0 from './assets/Lg-0.webp';
import cover1 from './assets/Lg-1.webp';
import cover2 from './assets/Lg-2.webp';
import cover3 from './assets/Lg-3.webp';
import cover4 from './assets/Lg-4.webp';
import cover5 from './assets/Lg-5.webp';
import cover6 from './assets/Lg-6.webp';
import cover7 from './assets/Lg-7.webp';
import cover8 from './assets/Lg-8.webp';
import coverXL0 from './assets/XL-0.jpg';
import coverXL1 from './assets/XL-1.jpg';
import coverXL2 from './assets/XL-2.jpg';
import coverXL3 from './assets/XL-3.webp';
import coverXL4 from './assets/XL-4.webp';
import coverXL5 from './assets/XL-5.webp';
import coverXL6 from './assets/XL-6.webp';
import coverXL7 from './assets/XL-7.jpg';
import coverXL8 from './assets/XL-8.webp';

export const DEFAULT_THEME = [
  {
    coverUrl: cover0,
    coverUrlXL: coverXL0,
    color: '125,39,170',
  },
  {
    coverUrl: cover1,
    coverUrlXL: coverXL1,
    color: '96,47,214',
  },
  {
    coverUrl: cover2,
    coverUrlXL: coverXL2,
    color: '96,47,214',
  },
  {
    coverUrl: cover3,
    coverUrlXL: coverXL3,
    color: '96,47,214',
  },
  {
    coverUrl: cover4,
    coverUrlXL: coverXL4,
    color: '58,85,197',
  },
  {
    coverUrl: cover5,
    coverUrlXL: coverXL5,
    color: '203,70,63',
  },
  {
    coverUrl: cover6,
    coverUrlXL: coverXL6,
    color: '49,143,72',
  },
  {
    coverUrl: cover7,
    coverUrlXL: coverXL7,
    color: '125,39,170',
  },
  {
    coverUrl: cover8,
    coverUrlXL: coverXL8,
    color: '200,36,156',
  },
];
