import { useFormatter, useTranslations } from 'next-intl';

import { BadgeAnatomy } from '@zealy/design-system';
import { RefreshCwLine } from '@zealy/icons';

import { QuestData } from '../../QuestCard.types';

export const Recurrence = (props: QuestData) => {
  const t = useTranslations('quest.recurrence');
  const format = useFormatter();

  if (props.recurrence === 'once') return null;

  return (
    <BadgeAnatomy.Root size="xs" className="min-w-0 flex-shrink">
      <BadgeAnatomy.Icon className="flex-shrink-0">
        <RefreshCwLine />
      </BadgeAnatomy.Icon>
      <BadgeAnatomy.Label className="overflow-ellipsis min-w-0 overflow-hidden">
        {t(`options.${props.recurrence}`)}{' '}
        {!props.opened &&
          props.openAt &&
          t('reset', {
            in: format.relativeTime(new Date(props.openAt), new Date()),
          })}
      </BadgeAnatomy.Label>
    </BadgeAnatomy.Root>
  );
};
