import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { Badge } from '@zealy/design-system';
import { ChevronRightFilled } from '@zealy/icons';

import type { QuestData } from '#components/QuestCard/QuestCard.types';
import { RewardCard } from '#components/RewardCard';
import { flattenRewardMethod } from '#utils/flattenReward';

import type { RewardContainerVariants } from './RewardStack.style';
import { rewardContainer, stage } from './RewardStack.style';

const rewardOrder = {
  token: 2,
  nft: 3,
  role: 4,
  other: 5,
  xp: 6,
} as const;

const getRewardSortScore = (r: QuestData['rewards'][number]) => {
  if (r.method?.type === 'vote' && r.status === 'in-progress') {
    return 1;
  }
  return rewardOrder[r.type];
};

const sortRewards = (a: QuestData['rewards'][number], b: QuestData['rewards'][number]) => {
  return getRewardSortScore(a) - getRewardSortScore(b);
};

export const RewardStack = ({ quest }: { quest: QuestData }) => {
  const [expanded, setExpanded] = useState(false);

  const t = useTranslations('common');

  const rewardCards = (quest.rewards || []).sort(sortRewards).slice(0, 3);
  const rewardsCount = rewardCards.length;
  const containVoteRewardInProgress = (quest.rewards || []).find(
    r => r.method?.type === 'vote' && r.status === 'in-progress',
  );

  if (!rewardsCount) return null;

  const toggleExpanded = () => setExpanded(prev => !prev);

  const claimAvailable = quest.rewards.some(r => r.status === 'claim-available');

  const isRewardsAreaExpandable = !claimAvailable && rewardsCount > 1;
  const isRewardsAreaDisabled = quest.locked;

  const onRewardsClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isRewardsAreaDisabled && !containVoteRewardInProgress) return;

    if (!isRewardsAreaExpandable) {
      return;
    }

    toggleExpanded();
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      data-testid="rewards"
      onClick={onRewardsClick}
      className={clsx(
        'group/rewards z-10 flex items-center content-center py-quest-card-md-left pr-quest-card-md-left right-0 min-w-[80px]',
        expanded && 'absolute inset-0',
      )}
      data-expanded={expanded}
      data-expendable={isRewardsAreaExpandable}
    >
      <div className={stage}>
        <ChevronRightFilled className="opacity-0 transition-transform duration-1000 translate-x-400 group-data-[expanded=true]/rewards:opacity-100 group-data-[expanded=true]/rewards:translate-x-0 absolute left-400" />
        <div className="flex gap-200 items-center group-data-[expanded=false]/rewards:-mr-100 group-data-[expanded=false]/rewards:[&>*:not(:first-child)>div>div>svg]:hidden">
          {rewardCards.map(({ method, ...reward }, index) => {
            const diff = 3 - rewardsCount;
            const position = (index + diff) as RewardContainerVariants['position'];
            const scale = index as RewardContainerVariants['scale'];

            const rewardClassName = rewardContainer({
              position,
              scale,
            });
            return (
              <RewardCard
                data-testid="reward-card"
                key={`${reward.type}-${index}`}
                {...{
                  ...reward,
                  ...flattenRewardMethod(method),
                  disabled: isRewardsAreaDisabled,
                  bgColor: 'var(--color-bg-secondary)',
                  overlay: index > 0 && !expanded,
                  className: rewardClassName,
                }}
                displayVoteButton={!expanded}
                quest={quest}
              />
            );
          })}
        </div>
      </div>
      {claimAvailable && (
        <Badge size="sm" className="absolute right-[38px] bottom-100 z-[200] bg-brand-primary">
          {t('claim')}
        </Badge>
      )}
    </div>
  );
};
