'use client';

import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import {
  Button,
  CONDITION_ICONS,
  Drawer,
  DrawerContent,
  DrawerTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@zealy/design-system';
import { CheckCircle2Filled } from '@zealy/icons';
import {
  useAuthenticatedUser,
  useCommunityDiscordRoles,
  useNFTCollectionMetadataBatch,
} from '@zealy/queries';

import { ConnectButton } from '#features/Wallets/EVM/ConnectButton';
import { useDebouncedValue } from '#hooks/useDebouncedValue';
import { useIsMobile } from '#hooks/useIsMobile';
import { useQuestFromQuestboard } from '#hooks/useQuestFromQuestboard';

import type { Matcher, QuestUnlockInfoProps } from './QuestUnlockInfo.types';

const CHECK_ICON = <CheckCircle2Filled className="text-success-primary" />;

const renderMatcher =
  (
    t: ReturnType<typeof useTranslations>,
    pathname: string,
    user: ReturnType<typeof useAuthenticatedUser<CommunityMember>>,
    router: ReturnType<typeof useRouter>,
  ) =>
  // eslint-disable-next-line react/display-name
  (matcher: Matcher, index: number) => {
    const operator = t(
      // @ts-ignore
      `quest.conditions.options.${matcher.type}.operator.${matcher.operator}.long`,
    );

    let value = matcher.type === 'date' ? new Date(matcher.value) : matcher.value;
    const unit = matcher.type === 'nft' ? matcher.value : undefined;

    if (matcher.type === 'quest' || matcher.type === 'role' || matcher.type === 'nft') {
      value = matcher.extraData?.name ? `"${matcher.extraData?.name}"` : '';
    }

    // @ts-ignore
    const label = t(`quest.conditions.options.${matcher.type}.label`, {
      operator,
      value: value
        ? value
        : // @ts-ignore
          t('common.deleted', {
            entity: matcher.type,
          }),
      unit,
    });

    const rootPath = pathname?.substring(0, pathname.indexOf('/questboard'));

    const href =
      matcher.type === 'quest' && matcher.extraData?.categoryId
        ? `${rootPath}/questboard/${matcher.extraData.categoryId}/${matcher.value}`
        : undefined;

    if (matcher.type === 'nft' && !user.data?.addresses?.['eth-mainnet'])
      return (
        <ConnectButton
          blockchain="eth-mainnet"
          buttonProps={{ className: 'w-full', color: 'default' }}
        />
      );

    return (
      <Button
        key={`${matcher.type}-${index}`}
        variant="muted"
        className="w-full whitespace-pre-wrap"
        isDisabled={matcher.fulfilled}
        leftIcon={CONDITION_ICONS[matcher.type]}
        rightIcon={matcher.fulfilled ? CHECK_ICON : undefined}
        as={href ? Link : undefined}
        href={href}
      >
        <span className="flex-1 w-0 line-clamp-1 overflow-ellipsis text-start">{label}</span>
      </Button>
    );
  };

export const QuestUnlockInfo = ({
  matchers = [],
  open,
  onHide,
  children,
  operator,
}: QuestUnlockInfoProps) => {
  const pathname = usePathname();
  const t = useTranslations();
  const user = useAuthenticatedUser<CommunityMember>();
  const router = useRouter();

  const matcherMap = renderMatcher(t, pathname, user, router);

  // Get all quest ids from conditions
  const ids = (matchers
    ?.filter(matcher => matcher.type === 'quest' && matcher.value)
    .map(matcher => matcher.value) ?? []) as string[];

  const nftsContractAddresses = (matchers
    ?.filter(matcher => matcher.type === 'nft' && matcher.contractAddress)
    .map(matcher => (matcher.type === 'nft' ? matcher.contractAddress : '')) ?? []) as string[];

  const nftsContractMetadata = useNFTCollectionMetadataBatch(nftsContractAddresses);

  const quests = useQuestFromQuestboard(ids);

  const includesRoles = matchers?.some(matcher => matcher.type === 'role');
  const roles = useCommunityDiscordRoles(undefined, { enabled: includesRoles });

  const isMobile = useIsMobile();

  const conditions = matchers?.map(matcher => {
    if (matcher.type === 'quest' && quests[matcher.value]) {
      return {
        ...matcher,
        extraData: quests[matcher.value],
      };
    }
    if (matcher.type === 'nft') {
      const metadata = nftsContractMetadata.data?.find(
        contract => contract.address.toLowerCase() === matcher.contractAddress.toLowerCase(),
      );
      return {
        ...matcher,
        extraData: metadata,
      };
    }
    if (matcher.type === 'role' && roles.data) {
      const role = roles.data.find(({ id }) => id === matcher.value);
      return {
        ...matcher,
        extraData: role,
      };
    }
    return matcher;
  });

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen && onHide) {
      onHide();
    }
  };

  const description =
    conditions.length > 1 &&
    t('ds.unlock-popover.description', {
      operator,
    });

  const isMobileDebounced = useDebouncedValue(isMobile, 3000);

  if (isMobileDebounced)
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        {conditions.length > 0 && (
          <DrawerContent>
            <p className="body-component-lg-bold">{t('ds.unlock-popover.title')}</p>
            {description && <p className="body-component-md text-secondary">{description}</p>}
            <div className="flex flex-col w-full gap-150">{conditions.map(matcherMap)}</div>
          </DrawerContent>
        )}
      </Drawer>
    );

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent title={t('ds.unlock-popover.title')} description={description} side="right">
        <div className="flex flex-col w-full gap-150">{conditions.map(matcherMap)}</div>
      </PopoverContent>
    </Popover>
  );
};
